<template>
  <div>
    <div class="card">
      <div v-if="nfts.length" class="my-transactions">
        <h5>Transactions</h5>
        <DataTable
          :value="nfts"
          dataKey="id"
          v-model:nfts="nfts"
          responsiveLayout="scroll"
          paginator
          lazy
          :rows="rows"
          :totalRecords="totalItems"
          @page="setPage"
        >
          <Column
            header="id"
            field="id"
            style="width: 20px"
          >
          </Column>
          <Column
            header="tNumber"
            field="tokenNumber"
            style="width: 20px"
          >
          </Column>

          <Column
            header="nameEng"
            field="company.nameEng"
            style="width: 40px"
          >
          </Column>
          <Column
            header="productName"
            field="productName"
            style="width: 40px"
          >
          </Column>
          <Column
            header="price"
            field="price"
            style="width: 40px"
          >
          </Column>
          <Column
            header="description"
            field="description"
            style="word-break: break-all"
          >
          </Column>
          <Column header="thumb"  style="word-break: break-all">
            <template #body="slot">
              <img :src="slot.data.thumb" :alt="slot.data.productName" style="width: 50px; display: block;" />
            </template>
          </Column>

        </DataTable>
      </div>
      <div v-else class="no-transactions">
        <p>Not any nfts yet</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Marketplace',
  head() {
    return {
      title: 'Marketplace',
    }
  },
  data() {
    return {
      nfts: {},
      item: {},
      page: 1,
      itemsPerPage: 30,
      rows: 30,
      totalItems: 10,
    }
  },
  async mounted() {
    this.getNfts()
  },
  methods: {
    setPage(event) {
      this.page = event.page + 1
      this.getNfts()
    },
    async getNfts() {
      const headers = {
        accept: 'application/ld+json',
        'Content-Type': 'application/ld+json',
      }
      const params = {
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        pagination: true
      }
      try {
        const resp = await this.axios.get('api/nfts', {
          headers,
          params,
        })
        this.nfts = resp.data['hydra:member']
        this.totalItems = resp.data['hydra:totalItems']
      } catch (error) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Error in api/transactions api call',
          group: 'info',
          life: 3000,
        })
      }
    },
  },
}
</script>

<style scoped>
[data-help] {
  position: relative;
}
[data-help]::after {
  content: attr(data-help);
  position: absolute;
  left: 7px;
  top: 0;
  pointer-events: none;
  opacity: 0;
  transition: 0.5s;
}
[data-help]:hover::after {
  opacity: 1;
  top: -1.5em;
}
</style>
